import React, { useEffect, useState } from "react";
import styled from "styled-components";
import BetsService from "../../services/bets";
import LoadingIndicator from "../Shared/LoadingIndicator";

const GenericLiveOdds = ({ provider, limit, random }) => {
  const [betData, setBetData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);

  const placeholderLogo =
    "https://static.draft5.gg/assets/team-logo-placeholder.png";

  useEffect(() => {
    trackWidgetImpression();

    BetsService.getLiveOddsWithGameKey(
      provider.PROVIDER_KEY,
      "draft5csgo"
    ).then(
      (response) => {
        {
          !random && setBetData(response.data.data.slice(0, limit));
        }
        {
          !!random &&
            setBetData(
              response.data.data.sort(() => Math.random() - 0.5).slice(0, limit)
            );
        }
        setIsLoading(false);
      },
      () => {
        setError(true);
        setIsLoading(false);
      }
    );
  }, []);

  const trackWidgetImpression = () => {
    window.dataLayer.push({
      event: "LiveOdds300Impression",
      liveOddsProvider: provider.PROVIDER_KEY,
    });
  };

  const trackWidgetClick = () => {
    window.dataLayer.push({
      event: "LiveOdds300Click",
      liveOddsProvider: provider.PROVIDER_KEY,
    });
  };

  return (
    <LiveOddsContainer provider={provider}>
      <ProviderLogo
        onClick={trackWidgetClick}
        href={provider.PROVIDER_URL}
        // href="https://rivalry.com/afialiado"
        target="_blank"
        rel="noreferrer noopener"
      >
        <img src={provider.PROVIDER_LOGO} />
      </ProviderLogo>

      {/* ========================== */}
      {/* LOADING ================== */}
      {/* ========================== */}
      {isLoading && <LoadingIndicator />}

      {/* ========================== */}
      {/* EMPTY STATE ============== */}
      {/* ========================== */}
      {!isLoading && betData.length === 0 && (
        <LiveOddMatch provider={provider}>
          <span>Nenhuma partida encontrada.</span>
        </LiveOddMatch>
      )}

      {/* ========================== */}
      {/* ERROR STATE ============== */}
      {/* ========================== */}
      {!isLoading && error && (
        <LiveOddMatch provider={provider}>
          <span>Ocorreu um erro, tente novamente.</span>
        </LiveOddMatch>
      )}

      {/* ========================== */}
      {/* NORMAL STATE============== */}
      {/* ========================== */}
      {!isLoading &&
        betData.length > 0 &&
        betData.map((match) => (
          <LiveOddMatch
            // href={match.teamA?.link}
            href={provider.PROVIDER_URL}
            title={`${match.teamA.name} vs. ${match.teamB.name} - ${match.tournamentName}`}
            target="_blank"
            rel="noopener noreferrer"
            provider={provider}
            key={`${match.teamA.name}-${match.teamB.name}-${match.matchId}`}
            onClick={() => {
              trackWidgetClick(
                `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
              );
            }}
          >
            <img
              src={`https://${match.teamA?.logo}`}
              width="30"
              alt={match.teamA.name}
              title={match.teamA.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholderLogo;
              }}
            />
            {match.teamA.odd && match.teamB.odd && (
              <>
                <OddBtn
                  provider={provider}
                  // href={match.teamA?.link}
                  href={provider.PROVIDER_URL}
                  title={`Odd para vitória da ${match.teamA.name}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    trackWidgetClick(
                      `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
                    );
                  }}
                >
                  {match.teamA.odd.toFixed(2)}
                </OddBtn>
                <span>vs</span>
                <OddBtn
                  provider={provider}
                  // href={match.teamB?.link}
                  href={provider.PROVIDER_URL}
                  title={`Odd para vitória da ${match.teamB.name}`}
                  target="_blank"
                  rel="noreferrer noopener"
                  onClick={() => {
                    trackWidgetClick(
                      `${match.matchId} - ${match.teamA.name} vs ${match.teamB.name}`
                    );
                  }}
                >
                  {match.teamB.odd.toFixed(2)}
                </OddBtn>
              </>
            )}

            {(!match.teamA?.odd || !match.teamB?.odd) && (
              <OddBtnSeeOdds
                provider={provider}
                // href={match.teamA?.link}
                href={provider.PROVIDER_URL}
                target="blank"
                rel="noopener noreferrer"
              >
                ver odds
              </OddBtnSeeOdds>
            )}
            <img
              src={`https://${match.teamB.logo}`}
              width="30"
              alt={match.teamB.name}
              title={match.teamB.name}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null;
                currentTarget.src = placeholderLogo;
              }}
            />
        <BetDescrition provider={provider}>
          Aposte em {match.teamA.name} vs. {match.teamB.name} na {match.tournamentName.length > 12 ? `${match.tournamentName.substring(0, 12)}...` : match.tournamentName}
        </BetDescrition>
                </LiveOddMatch>
        ))}
      <Disclaimer provider={provider}>
        +18. Aposte com responsabilidade. Odds sujeitas a alteração.
      </Disclaimer>
    </LiveOddsContainer>
  );
};

const BetDescrition = styled.div`
  width: 100%;
  font-size: 9px;
  color: ${(p) => p.provider.colors.TEXT};
  display: flex;
  justify-content: center;

  @media screen and (max-width: 768px) {
    font-size: 18px;
  }
`;

const LiveOddsContainer = styled.div`
  background: ${(p) => p.provider.colors.BACKGROUND_DARKER};

  border-top: 5px solid ${(p) => p.provider.colors.ACCENT};
  margin-bottom: 16px;
  padding: 16px 12px;

  img {
    content-visibility: auto;
  }
`;

const ProviderLogo = styled.a`
  display: block;
  text-align: center;

  img {
    width: 110px;
  }

  @media screen and (max-width: 768px) {
    img {
      width: 150px;
    }
  }
`;

const LiveOddMatch = styled.a`
  background-color: ${(p) => p.provider.colors.BACKGROUND};
  height: 50px;
  margin-top: 10px;

  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  transition: all 0.2s;
  flex-wrap: wrap;
  row-gap: 1px;

  &:hover {
    background-color: ${(p) => p.provider.colors.HOVER_COLOR};
  }

  span {
    color: ${(p) => p.provider.colors.ACCENT};
    font-size: 12px;
    padding: 0 10px;
  }

  img {
    max-width: 32px;
    max-height: 32px;
    margin: 0 10px;
  }

  @media screen and (max-width: 768px) {
    height: 80px;
    justify-content: center;

    span {
      font-size: 20px;
      padding: 0 20px;
    }

    img {
      max-width: 64px;
      max-height: 64px;
      margin: 0 20px;
    }
  }
`;

const OddBtn = styled.div`
  background: ${(p) => p.provider.colors.BACKGROUND_DARKER};
  border-radius: 5px;
  margin: 0;
  cursor: pointer;
  color: ${(p) => p.provider.colors.TEXT};
  font-weight: 700;
  font-size: 12px;
  line-height: 32px;


  height: 32px;
  width: 38px;
  text-align: center;
  transition: all 0.2s;

  ${(p) =>
    p.block &&
    `
    width: 100%;
    max-width: 100%;
    flex: 1;
    margin: 0 12px;
  `}

  &:hover {
    background: ${(p) => p.provider.colors.ACCENT};
    text-shadow: none;
    color: ${(p) => p.provider.colors.HOVER_TEXT_COLOR};
  }

  @media screen and (max-width: 768px) {
    line-height: 48px;
    font-size: 20px;
    height: 48px;
    width: 64px;
  }
`;

const OddBtnSeeOdds = styled(OddBtn)`

flex-basis: fit-content;
padding: 0 30px;

@media screen and (max-width: 768px) {
  padding: 0 60px;
}
`;

const Disclaimer = styled.div`
  text-align: center;
  color: ${(p) => p.provider.colors.TEXT};
  font-size: 9px;
  font-style: italic;

  margin-top: 10px;
  background-color: ${(p) => p.provider.colors.BACKGROUND};
  padding: 6px 3px;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export default GenericLiveOdds;
